import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'

// Provide dynamic titles , descriptions, and meta tags for all components
export default function Seo({ title, description, isBlogPost }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const { defaultTitle, titleTemplate, defaultDescription, siteUrl, lang } =
    site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={pathname === '/' ? null : titleTemplate}
      htmlAttributes={{ lang }}
    >
      <script type='application/ld+json'>
        {`
          {
            "@context": "https://schema.org",
            "@type": "LegalService",
            "url": "${siteUrl}",
            "name": "Linden Law Group",
            "legalName": "Linden Kominek PC",
            "description": "${defaultDescription}",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "111 S Tejon St, Suite 202",
              "addressLocality": "Colorado Springs",
              "addressRegion": "CO",
              "postalCode": "80903",
              "addressCountry": "US"
            },
            "hasMap": "https://www.google.com/maps/dir//111+S+Tejon+St,+Colorado+Springs,+CO+80903",
            "contactPoint": {
              "@type": "ContactPoint",
              "email": "vince@lindenlawgroup.com",
              "telephone": "(719) 955-0078"
            }
          }
        `}
      </script>

      <meta name='description' content={seo.description} />
      <meta property='og:url' content={seo.url} />
      <meta property='og:title' content={seo.title} />
      <meta property='og:description' content={seo.description} />
      <meta property='og:type' content={isBlogPost ? 'article' : 'website'} />
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        lang
      }
    }
  }
`
