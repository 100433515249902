import React from 'react'
import { Link } from 'gatsby'
import * as style from '../styles/header.module.css'
import LogoIcon from '../images/svg/logo.svg'
import { useLocation } from '@reach/router'

export default function Header({ menuOpen, showMenu }) {
  const { pathname } = useLocation()

  return (
    <header className={style.header}>
      <LogoIcon
        className={style.logo}
        role='button'
        tabIndex={0}
        onClick={showMenu}
        onKeyDown={e => e.code === 'Enter' && showMenu()}
        aria-hidden={false}
      />

      <span
        className={style.name}
        role='button'
        tabIndex={0}
        onClick={showMenu}
        onKeyDown={e => e.code === 'Enter' && showMenu()}
      >
        LINDEN LAW GROUP
      </span>

      {pathname !== '/contact' && (
        <Link to='/contact' className={style.contact}>
          Contact Us
        </Link>
      )}

      {menuOpen && (
        <div className={style.base}>
          <nav className={style.menu}>
            <Link to='/'>Home</Link>
            <Link to='/about'>Who We Are</Link>
            <Link to='/services'>What We Do</Link>
            <Link to='/results'>Where We've Been</Link>
            <Link to='/mission'>Why Us</Link>
            <Link to='/blog'>What We're Thinking</Link>

            <a
              href='https://secure.lawpay.com/pages/lindenlawgroup/operating'
              target='_blank'
              rel='noopener noreferrer'
            >
              Pay Online
            </a>

            <Link to='/contact'>Contact</Link>
          </nav>

          <div className={style.dimmer} />
        </div>
      )}
    </header>
  )
}
