import React, {
  useState,
  useEffect,
  useCallback,
  isValidElement,
  cloneElement,
  Children,
} from 'react'

import Header from './header'
import Footer from './footer'

// Load site-wide fonts
import '@fontsource/libre-baskerville'
import '@fontsource/playfair-display'

import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'

// Load site-wide styles
import '../styles/layout.css'

export default function Layout({ showFooter, children }) {
  const [menuOpen, setMenuOpen] = useState(false)

  // Handle changes to menu state
  const showMenu = () => setMenuOpen(true)

  const closeMenu = useCallback(() => {
    setMenuOpen(false)
  }, [])

  const closeMenuOnKeydown = useCallback(e => {
    if (e.code === 'Enter') {
      // Link selected -> click it, logo selected -> close menu
      if (e.target.nodeName === 'A') {
        e.target.click()
      } else {
        setMenuOpen(false)
      }
    }
  }, [])

  // Handle clicks outside of menu once it's open
  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('click', closeMenu)
      document.addEventListener('keydown', closeMenuOnKeydown)
    } else {
      document.removeEventListener('click', closeMenu)
      document.removeEventListener('keydown', closeMenuOnKeydown)
    }
  }, [menuOpen, closeMenu, closeMenuOnKeydown])

  // Pass menu functions down to children
  const childrenWithProps = Children.map(children, child =>
    isValidElement(child) ? cloneElement(child, { showMenu }) : child
  )

  return (
    <div id='wrapper'>
      <Header menuOpen={menuOpen} showMenu={showMenu} />
      <main>{childrenWithProps}</main>
      {showFooter && <Footer />}
    </div>
  )
}
